var ccApiKey = 'a566c47558e590321c1eb0d9e14d61e9f6d5fc35';
var ccProduct = 'COMMUNITY';
window.dataLayer = window.dataLayer || [];

var config = {
  apiKey: ccApiKey,
  product: ccProduct,
  mode: "GDPR",
  text: {
    intro: "We use cookies to enable certain functionality, understand how our website is used and for targeted advertising purposes.  These cookies may be read by third parties and other websites you visit.  We also share information about your use of our website with our analytics partners.",
    acceptRecommended: 'Accept all cookies',
    rejectSettings: 'Reject all cookies'

  },
  closeStyle: 'button',
  rejectButton: true,
  acceptBehaviour: "recommended",
  statement: {
    description: "For more information, please see our ",
    name: "cookie policy.",
    url: "/cookie-policy",
    updated: "21/10/2024",
  },
  necessaryCookies: ['has_js', '_hjid', 'visid_incap', 'SSESS*',],
  optionalCookies: [
    {
      name : 'analytics',
      label: 'Analytical Cookies',
      description: 'Analytical cookies help us to improve our website by collecting and reporting information on its usage.',
      cookies: [        
        '_ga',
        '_ga*',
        '_gid',
        '_gat',
        '_gat_*',
        '_gali',
        '__utma',
        '__utmt',
        '__utmb',
        '__utmc',
        '__utmz',
        '__utmv',
        '_hjIncludedInSessionSample',
        '_hjSessionUser',
        '_hjSessionUser_*',
        '_hjSession_*',
        '_hjAbsoluteSessionInProgress',
        '_hjSession', 
        '_hjFirstSeen',
        'fpestid',
        'ph_phc_*',
    ],
      onAccept: function(){
        gtag('consent', 'update', {'analytics_storage': 'granted' }); 
      },
      onRevoke: function(){
        gtag('consent', 'update', {'analytics_storage': 'denied' });       
      },
      recommendedState: true,
      lawfulBasis: "consent",
    }, 
    {
      name: 'Marketing',
      label: 'Marketing cookies',
      description: 'We use marketing cookies to help us improve the relevancy of advertising campaigns you receive.',
      cookies: [
        "_gcl_au",
        "IDE",
        "DV",
        "NID",
        'wc_client',
        'wc_client_current',
        'wc_swap',
        'wc_visitor',
      ],
      onAccept: function(){
        gtag('consent', 'update', {'ad_storage': 'granted',
                                    'ad_user_data': 'granted',
                                    'ad_personalization': 'granted'
                                  }); 
      },
    onRevoke: function(){
        gtag('consent', 'update', {'ad_user_data': 'denied',
                                   'ad_personalization': 'denied',
                                   'ad_storage': 'denied'
                                  });       
      },
    recommendedState: true,
    lawfulBasis: "consent",
    },
  ],
  layout:'slideout',
  position: "RIGHT",
  theme: "dark",
  "iabCMP": false,
  acceptButton: true,
  rejectButton: false,
  toggleType: 'slider',
  notifyOnce: false,
  notifyDismissButton: false,
};


CookieControl.load( config );
